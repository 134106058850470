export default [
    {
        path: '/business-line/restaurants',
        name: 'restaurants',
        component: () => import('@/views/businessline/restaurants/list/RestaurantList.vue'),
        meta: {
            title: 'Business Line > Restaurants - AKARA CMS',
            pageTitle: 'BUSINESS LINE | RESTAURANTS',
            pageSubTitle: 'Show all supply chains of the restaurant on the website',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                },
                {
                    text: 'Restaurants',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/business-line/restaurant/create',
        name: 'restaurant-create',
        component: () => import('@/views/businessline/restaurants/create/RestaurantCreate.vue'),
        meta: {
            title: 'Restaurants > Add - AKARA CMS',
            pageTitle: 'ADD RESTUARANT',
            pageSubTitle: 'Create new restuarant on website',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                },
                {
                    text: 'Restaurants',
                    to: '/business-line/restaurants',
                },
                {
                    text: 'Add Restuarant',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/business-line/restaurant/edit/:id',
        name: 'restaurant-edit',
        component: () => import('@/views/businessline/restaurants/create/RestaurantCreate.vue'),
        meta: {
            title: 'Restaurants > Edit - AKARA CMS',
            pageTitle: 'Edit RESTUARANT',
            pageSubTitle: 'Edit restuarant on website',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                },
                {
                    text: 'Restaurants',
                    to: '/business-line/restaurants',
                },
                {
                    text: 'Edit Restuarant',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/business-line/nightclubs',
        name: 'nightclubs',
        component: () => import('@/views/businessline/nightclubs/list/NightclubList.vue'),
        meta: {
            title: 'Business Line > Nightclubs - AKARA CMS',
            pageTitle: 'BUSINESS LINE | NIGHTCLUBS',
            pageSubTitle: 'Show all supply chains of the nightclub on the website',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                },
                {
                    text: 'Nightclubs',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/business-line/nightclub/create',
        name: 'nightclub-create',
        component: () => import('@/views/businessline/nightclubs/create/NightclubCreate.vue'),
        meta: {
            title: 'Nightclubs > Add - AKARA CMS',
            pageTitle: 'ADD NIGHTCLUB',
            pageSubTitle: 'Create new nightclubs on website',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                },
                {
                    text: 'Nightclubs',
                    to: '/business-line/nightclubs',
                },
                {
                    text: 'Add Nightclub',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/business-line/nightclub/edit/:id',
        name: 'nightclub-edit',
        component: () => import('@/views/businessline/nightclubs/create/NightclubCreate.vue'),
        meta: {
            title: 'Nightclubs > Edit - AKARA CMS',
            pageTitle: 'Edit NIGHTCLUB',
            pageSubTitle: 'Edit nightclub on website',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                },
                {
                    text: 'Nightclubs',
                    to: '/business-line/nightclubs',
                },
                {
                    text: 'Edit Nightclub',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/business-line/hotels',
        name: 'hotels',
        component: () => import('@/views/miscellaneous/ComingSoon.vue'),
        meta: {
            title: 'Business Line > Hotels - AKARA CMS',
            layout: 'full',
        }
    },
]
