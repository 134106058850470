export default [
    {
        path: '/home/banner/list',
        name: 'home-banner-list',
        component: () => import('@/views/home/banner/list/BannerList.vue'),
        meta: {
            title: 'Home > Banner - AKARA CMS',
            pageTitle: 'Banner',
            pageSubTitle: 'Manage and organize content on the home page.',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                    items: 'start',
                },
                {
                    text: 'Banner',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/home/banner/create',
        name: 'home-banner-create',
        component: () => import('@/views/home/banner/create/BannerCreate.vue'),
        meta: {
            title: 'Home > Banner - AKARA CMS',
            pageTitle: 'Add Banner',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                    items: 'start',
                },
                {
                    text: 'Banner',
                    to: '/home/banner/list',
                },
                {
                    text: 'Add Banner',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/home/banner/edit/:id',
        name: 'home-banner-edit',
        component: () => import('@/views/home/banner/edit/BannerEdit.vue'),
        meta: {
            title: 'Home > Banner - AKARA CMS',
            pageTitle: 'Edit Banner',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                    items: 'start',
                },
                {
                    text: 'Banner',
                    to: '/home/banner/list',
                },
                {
                    text: 'Edit Banner',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/home/testimonial/list',
        name: 'home-testimonial-list',
        component: () => import('@/views/home/testimonial/list/TestimonialList.vue'),
        meta: {
            title: 'Home > Testimonial - AKARA CMS',
            pageTitle: 'Testimonial',
            pageSubTitle: 'Manage and organize content on the home page.',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                    items: 'start',
                },
                {
                    text: 'Testimonial',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/home/testimonial/create',
        name: 'home-testimonial-create',
        component: () => import('@/views/home/testimonial/create/TestimonialCreate.vue'),
        meta: {
            title: 'Home > Testimonial - AKARA CMS',
            pageTitle: 'Add Testimonial',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                    items: 'start',
                },
                {
                    text: 'Testimonial',
                    to: '/home/testimonial/list',
                },
                {
                    text: 'Add Testimonial',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/home/testimonial/edit/:id',
        name: 'home-testimonial-edit',
        component: () => import('@/views/home/testimonial/edit/TestimonialEdit.vue'),
        meta: {
            title: 'Home > Testimonial - AKARA CMS',
            pageTitle: 'Edit Testimonial',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                    items: 'start',
                },
                {
                    text: 'Testimonial',
                    to: '/home/testimonial/list',
                },
                {
                    text: 'Edit Testimonial',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/home/setting',
        name: 'home-setting',
        component: () => import('@/views/home/setting/SettingDetail.vue'),
        meta: {
            title: 'Home > Page Setting - AKARA CMS',
            pageTitle: 'Home Setting',
            pageSubTitle: 'Manage and organize content on the home page.',
            breadcrumb: [
                {
                    text: 'Home',
                    icon: 'HomeIcon',
                    items: 'start',
                },
                {
                    text: 'Page Setting',
                    active: true,
                },
            ],
        },
    },
]
