export default [
    {
        path: '/about/banner',
        name: 'about-banner',
        component: () => import('@/views/about/banner/BannerDetail.vue'),
        meta: {
            title: 'About > Banner - AKARA CMS',
            pageTitle: 'Banner',
            pageSubTitle: 'Manage and organize content on the about page.',
            breadcrumb: [
                {
                    text: 'About',
                    icon: 'AlertCircleIcon',
                },
                {
                    text: 'Banner',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/about/whoweare',
        name: 'about-whoweare',
        component: () => import('@/views/about/whoweare/list/WhoWeAreList.vue'),
        meta: {
            title: 'About > Who we are - AKARA CMS',
            pageTitle: 'Who we are',
            pageSubTitle: 'Add story about the company about who we are.',
            breadcrumb: [
                {
                    text: 'About',
                    icon: 'AlertCircleIcon',
                },
                {
                    text: 'Who we are',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/about/whoweare/create',
        name: 'about-whoweare-create',
        component: () => import('@/views/about/whoweare/create/WhoWeAreCreate.vue'),
        meta: {
            title: 'About > Who we are - AKARA CMS',
            pageTitle: 'Add who we are',
            pageSubTitle: 'Add story about the company about who we are.',
            breadcrumb: [
                {
                    text: 'About',
                    icon: 'AlertCircleIcon',
                },
                {
                    text: 'Who we are',
                    to: '/about/whoweare',
                },
                {
                    text: 'Add Who we are',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/about/whoweare/edit/:id',
        name: 'about-whoweare-edit',
        component: () => import('@/views/about/whoweare/edit/WhoWeAreEdit.vue'),
        meta: {
            title: 'About > Who we are - AKARA CMS',
            pageTitle: 'Edit who we are',
            pageSubTitle: 'Add story about the company about who we are.',
            breadcrumb: [
                {
                    text: 'About',
                    icon: 'AlertCircleIcon',
                },
                {
                    text: 'Who we are',
                    to: '/about/whoweare',
                },
                {
                    text: 'Edit Who we are',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/about/whatwedo',
        name: 'about-whatwedo',
        component: () => import('@/views/about/whatwedo/WhatWeDoDetail.vue'),
        meta: {
            title: 'About > What we do - AKARA CMS',
            pageTitle: 'What we do',
            pageSubTitle: 'Manage and organize content on the about page.',
            breadcrumb: [
                {
                    text: 'About',
                    icon: 'AlertCircleIcon',
                },
                {
                    text: 'What we do',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/about/mission',
        name: 'about-mission',
        component: () => import('@/views/about/mission/MissionDetail.vue'),
        meta: {
            title: 'About > Vision/Mission - AKARA CMS',
            pageTitle: 'Vision/Mission',
            pageSubTitle: 'Manage and organize content on the about page.',
            breadcrumb: [
                {
                    text: 'About',
                    icon: 'AlertCircleIcon',
                },
                {
                    text: 'Vision/Mission',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/about/gallery',
        name: 'about-gallery',
        component: () => import('@/views/about/gallery/list/GalleryList.vue'),
        meta: {
            title: 'About > Gallery - AKARA CMS',
            pageTitle: 'Gallery',
            pageSubTitle: 'Gallery show image about the company.',
            breadcrumb: [
                {
                    text: 'About',
                    icon: 'AlertCircleIcon',
                },
                {
                    text: 'Gallery',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/about/gallery/create',
        name: 'about-gallery-create',
        component: () => import('@/views/about/gallery/create/GalleryCreate.vue'),
        meta: {
            title: 'About > Gallery - AKARA CMS',
            pageTitle: 'Add image',
            pageSubTitle: 'Add image about the company.',
            breadcrumb: [
                {
                    text: 'About',
                    icon: 'AlertCircleIcon',
                },
                {
                    text: 'Gallery',
                    to: '/about/gallery'
                },
                {
                    text: 'Add Image',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/about/gallery/edit/:id',
        name: 'about-gallery-edit',
        component: () => import('@/views/about/gallery/edit/GalleryEdit.vue'),
        meta: {
            title: 'About > Gallery - AKARA CMS',
            pageTitle: 'Edit image',
            pageSubTitle: 'Edit image about the company.',
            breadcrumb: [
                {
                    text: 'About',
                    icon: 'AlertCircleIcon',
                },
                {
                    text: 'Gallery',
                    to: '/about/gallery'
                },
                {
                    text: 'Edit Image',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/about/setting',
        name: 'about-setting',
        component: () => import('@/views/about/setting/SettingDetail.vue'),
        meta: {
            title: 'About > Page Setting - AKARA CMS',
            pageTitle: 'About Setting',
            pageSubTitle: 'Manage and organize content on the about page.',
            breadcrumb: [
                {
                    text: 'About',
                    icon: 'AlertCircleIcon',
                },
                {
                    text: 'Page Setting',
                    active: true,
                },
            ],
        },
    },
]
