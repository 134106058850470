export default [
    {
        path: '/user/list',
        name: 'user-list',
        component: () => import('@/views/user/list/UserList.vue'),
        meta: {
            pageTitle: 'User Management',
            pageSubTitle: 'Manage users account on content management system.',
        },
    },
    {
        path: '/user/create',
        name: 'user-create',
        component: () => import('@/views/user/create/UserCreate.vue'),
        meta: {
            pageTitle: 'Add users',
            pageSubTitle: 'Invite the users via email for collaboration.',
            breadcrumb: [
                {
                    text: 'User Management',
                    to: '/user/list',
                },
                {
                    text: 'Add users',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/edit/:id',
        name: 'user-edit',
        component: () => import('@/views/user/edit/UserEdit.vue'),
        meta: {
            pageTitle: 'View & Edit User',
            pageSubTitle: 'Manage status, the role of account, and update employee information.',
            breadcrumb: [
                {
                    text: 'User Management',
                    to: '/user/list',
                },
                {
                    text: 'View & Edit User',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/profile',
        name: 'user-profile',
        component: () => import('@/views/user/profile/UserProfile.vue'),
        meta: {
            pageTitle: 'View & Edit User',
            pageSubTitle: 'Manage status, the role of account, and update employee information.',
            resource: 'User',
            breadcrumb: [
                {
                    text: 'User Management',
                },
                {
                    text: 'View & Edit User',
                    active: true,
                },
            ],
        },
    },
]
