export default [
    {
        path: '/member/counter-bar',
        name: 'member-counter-bar',
        component: () => import('@/views/team/counter-bar/CounterBar.vue'),
        meta: {
            title: 'Team > Counter Bar - AKARA CMS',
            pageTitle: 'COUNTER BAR',
        },
    },
    {
        path: '/member/list',
        name: 'member-list',
        component: () => import('@/views/team/list/MemberList.vue'),
        meta: {
            title: 'Team > Member - AKARA CMS',
            pageTitle: 'Member Management',
            pageSubTitle: 'Manage members.',
        },
    },
    {
        path: '/member/create',
        name: 'member-create',
        component: () => import('@/views/team/create/MemberCreate.vue'),
        meta: {
            title: 'Team > Member - AKARA CMS',
            pageTitle: 'Add members',
            breadcrumb: [
                {
                    text: 'Member Management',
                    to: '/member/list',
                },
                {
                    text: 'Add members',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/member/edit/:id',
        name: 'member-edit',
        component: () => import('@/views/team/edit/MemberEdit.vue'),
        meta: {
            title: 'Team > Member - AKARA CMS',
            pageTitle: 'View & Edit Member',
            pageSubTitle: 'Manage member status and information.',
            breadcrumb: [
                {
                    text: 'Member Management',
                    to: '/member/list',
                },
                {
                    text: 'View & Edit Member',
                    active: true,
                },
            ],
        },
    },
]
