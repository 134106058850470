export default [
    {
        path: '/contact',
        name: 'contact-us',
        component: () => import('@/views/contact/ContactDetail.vue'),
        meta: {
            title: 'Contact us - AKARA CMS',
            pageTitle: 'Contact us',
            pageSubTitle: 'Setting contact information for your company.',
            breadcrumb: [
                {
                    text: 'Contact us',
                    icon: 'MailIcon',
                    active: true,
                },
            ],
        },
    }
]
